import React from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { fetchChefleMenuRestaurantList } from '../../actions/restaurantList'
import { fetchImmediateChefleMenuRestaurantList } from '../../actions/immediateRestaurantList'
import isEqual from 'lodash/isEqual'
import { SideMenu } from '../../components/menu/SideMenu'
import { RestaurantCard } from '../../components/restaurant/RestaurantCard'
import { WhereToEat, getAreaName } from '../../components/top/WhereToEat'
import { WhatToEat, getCategoryName } from '../../components/top/WhatToEat'
import { fetchAreas } from '../../actions/areas'
import { fetchCategories } from '../../actions/categories'
import { getActiveCampaign } from '../../actions/campaign'
import { history } from '../../configureStore'
import cleanDeep from 'clean-deep'
import { Helmet } from 'react-helmet'
import { PcHeader, SpHeader } from '../../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import Notification from '../../components/common/Notification'
import ReactLoading from 'react-loading'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    restaurantList: state.restaurantList,
    immediateRestaurantList: state.immediateRestaurantList,
    areas: state.areas,
    categories: state.categories,
    campaign: state.campaign,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetch: bindActionCreators(fetchChefleMenuRestaurantList, dispatch),
    fetchImmediateChefleMenuRestaurantList: bindActionCreators(
      fetchImmediateChefleMenuRestaurantList,
      dispatch
    ),
    fetchAreas: bindActionCreators(fetchAreas, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch),
    getActiveCampaign: bindActionCreators(getActiveCampaign, dispatch),
  })
}

class ChefleMenuContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
    }
  }

  componentDidMount() {
    super.componentDidMount()
    const urlQuery = queryString.parse(this.props.location.search)
    this.props.fetchAreas()
    this.props.fetchCategories()
    this.props.fetch(Object.assign(urlQuery, this.props.match.params))
    this.props.fetchImmediateChefleMenuRestaurantList(
      Object.assign(urlQuery, this.props.match.params)
    )

    this.props.getActiveCampaign()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (
      !prevProps.campaign.data.id &&
      this.props.campaign.data.id &&
      !Object.keys(queryString.parse(this.props.location.search)).length
    ) {
      history.push(history.location.pathname + '?campaign=active')
      return
    }

    const urlQuery = Object.assign(
      queryString.parse(this.props.location.search),
      this.props.match.params
    )
    const prevQuery = Object.assign(
      queryString.parse(prevProps.location.search),
      prevProps.match.params
    )
    if (!isEqual(urlQuery, prevQuery)) {
      this.props.fetch(urlQuery)
      this.props.fetchImmediateChefleMenuRestaurantList(urlQuery)
    }
  }

  addParamQueryString(param) {
    let query = queryString.parse(this.props.location.search)
    return queryString.stringify(Object.assign(query, param))
  }

  renderPaging = restaurantList => {
    const { currentPage, lastPage } = restaurantList
    let array = []
    let hasLast = false
    let hasFirst = false

    let first = Math.max(currentPage - 1, 1)
    let last = Math.min(first + 3, lastPage)
    for (let i = first; i <= last; i++) {
      array.push({
        no: i,
        current: Number(currentPage) === i ? true : false,
      })
      if (i === 1) {
        hasFirst = true
      }
      if (i === lastPage) {
        hasLast = true
      }
    }
    if (!hasFirst) {
      array.unshift({ no: '...', current: false })
      array.unshift({ no: 1, current: false })
    }
    if (currentPage !== lastPage && hasLast === false) {
      array.push({ no: '...', current: false })
      array.push({ no: lastPage, current: false })
    }
    return array.map(a => (
      <React.Fragment key={a.no + '-page'}>
        {!a.current ? (
          <li>
            <Link
              to={`${history.location.pathname}?${this.addParamQueryString({
                page: a.no,
              })}`}
            >
              {a.no}
            </Link>
          </li>
        ) : (
          <li className="top-page-store-pager-active">
            <a className="top-page-store-pager-active" href={() => false}>
              {a.no}
            </a>
          </li>
        )}
      </React.Fragment>
    ))
  }

  makeHeading = (areaName, categoryName, campaignSelect) => {
    let headingWords = []

    let areaCategoryWords = []
    if (areaName && areaName !== '全エリア') {
      areaCategoryWords.push(areaName)
    }
    if (categoryName && categoryName !== '全料理ジャンル') {
      areaCategoryWords.push(categoryName)
    }
    if (areaCategoryWords.length > 0) {
      headingWords.push(areaCategoryWords.join('の'))
    }

    headingWords.push('厳選おすすめ人気店')

    let result = headingWords.join(' ')
    return result === '厳選おすすめ人気店' ? '' : result
  }

  makeDescription = (areaName, categoryName, campaignSelect) => {
    let descriptionWords = []

    descriptionWords.push(
      'Chefleは、有名店所属のシェフがあなたのご自宅や指定場所に伺い、コース料理を提供する派遣サービス（ケータリング）です。 プライベート空間で是非、贅沢な時間をお過ごしください。'
    )

    let result = descriptionWords.join(' ')
    return result
  }

  changeWhereToEat = area => {
    let url = this.createUrlWithoutPage(area, this.props.match.params.category)
    history.push(url)
  }

  changeWhatToEat = category => {
    let url = this.createUrlWithoutPage(this.props.match.params.area, category)
    history.push(url)
  }

  createUrlWithoutPage = (area, category) => {
    let url =
      area && category
        ? `/menu/${area}/${category}`
        : area && !category
        ? `/menu/${area}`
        : !area && category
        ? `/menu/cuisine/${category}`
        : '/'

    let query = queryString.parse(history.location.search)
    delete query.page

    return url + '?' + queryString.stringify(query)
  }

  changeFilters = payload => {
    let query = queryString.parse(history.location.search)
    query = Object.assign(query, payload)
    let filterd = cleanDeep(query)
    delete filterd.page
    history.push(
      history.location.pathname + '?' + queryString.stringify(filterd)
    )
  }

  //  人気ランキングソート
  sortPopularity = payload => {
    let query = queryString.parse(history.location.search)
    query = Object.assign(query, payload)
    let filterd = cleanDeep(query)
    delete filterd.page

    history.push(
      history.location.pathname + '?' + queryString.stringify(filterd)
    )
  }

  render() {
    const { restaurantList, immediateRestaurantList, authUser } = this.props
    const areaKey = this.props.match.params.area
    const categoryKey = this.props.match.params.category

    const areaName = getAreaName(this.props.areas.data, areaKey)
    const categoryName = getCategoryName(
      this.props.categories.data,
      categoryKey
    )

    const campaignSelect = queryString.parse(this.props.location.search)
      .campaign

    const heading = this.makeHeading(areaName, categoryName, campaignSelect)
    const resultString = {
      padding: '20px 10px 10px 10px',
      color: '#707070',
      fontSize: '20px',
    }

    const head = {
      title: heading
        ? `シェフル(chefle) - ${heading} ${
            restaurantList.currentPage > 1
              ? `（${restaurantList.currentPage}ページ目）`
              : ''
          }`
        : `シェフル(chefle) - 有名高級店を、予約代行いたします。${
            restaurantList.currentPage > 1
              ? `（${restaurantList.currentPage}ページ目）`
              : ''
          }`,
      description:
        'シェフル（Chefle）では、あなたに代わり高級レストラン来店のご予約を承ります。コース料金の事前決済に対応しておりますので、記念日や接待でのご利用など、スマート会計が可能です。ご自身では予約の取りにくい高級店にも展開しています。',
      page_url: 'https://chefle.com/',
      image: 'http://chefle.com/assets/img/ogp_booking.jpeg',
    }

    const pcSlideImages = ['/assets/img/chefle/top.jpg']

    const spSlideImages = ['/assets/img/chefle/top-sp.jpg']

    const spinnerProperties = isMobile
      ? { height: '30%', width: '30%' }
      : { height: '12%', width: '12%' }

    const category = categoryName === '全料理ジャンル' ? '' : categoryName

    const headDescription = `${areaName}のおすすめ${category}レストラン`

    const description = `${areaName}の選びぬかれた${category}レストランをご予約いただけます。料理、場所などで検索いただくことも可能です。もし不明な点がございましたら、コンシェルジュがサポートいたします。素敵なお食事をシェフルで予約しましょう。`

    const getLink = () => {
      const { currentPage, lastPage } = restaurantList
      if (currentPage === 1 && currentPage !== lastPage) {
        return (
          <link
            rel="next"
            href={`https://chefle.com/?page=${currentPage + 1}`}
          />
        )
      }
      if (currentPage === 1 && currentPage === lastPage) {
        return <link href="https://chefle.com" />
      }
      if (currentPage > 1 && currentPage !== lastPage) {
        if (currentPage === 2) {
          return <link rel="prev" href="https://chefle.com" />
        }
        return (
          <link
            rel="prev"
            href={`https://chefle.com/?page=${currentPage - 1}`}
          />
        )
      }
      if (currentPage === lastPage) {
        return (
          <link
            rel="prev"
            href={`https://chefle.com/?page=${currentPage - 1}`}
          />
        )
      }
    }

    const getLink2 = () => {
      const { currentPage, lastPage } = restaurantList
      if (currentPage > 1 && currentPage !== lastPage) {
        return (
          <link
            rel="next"
            href={`https://chefle.com/?page=${currentPage + 1}`}
          />
        )
      }
      return
    }

    return (
      <>
        <div
          className={
            campaignSelect && this.props.campaign.data.id
              ? 'top-page campaign'
              : 'top-page'
          }
        >
          <Helmet>
            {getLink()}
            {getLink2()}
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta property="og:url" content={head.page_url} />
            <meta name="description" content={head.description} />
            <meta property="og:image" content={head.image} />
          </Helmet>

          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle-menu'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle-menu'}
          />

          <section>
            <Notification />
            <div className="top-page-top">
              <div
                className="slide-title"
                Style={isMobile ? 'top: 58%;' : 'top: 44%;'}
              >
                有名高級店を、
                <br />
                予約代行いたします。
                <p>来店予約</p>
              </div>

              <div className="slide-sevices pc">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="メニュー"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      来店予約レストラン
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_chefle.png"
                      alt="在宅レストラン"
                    />
                    <span Style="background-color: white;">
                      在宅レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取"
                    />
                    <span Style="background-color: white;">
                      店頭受取レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="キッチンバナー"
                    />
                    <span Style="background-color: white;">
                      お取り寄せ一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
              </div>

              <div className="each-slide pc">
                <div
                  style={{
                    backgroundImage: `url(${pcSlideImages[0]})`,
                    width: '115%',
                  }}
                />
              </div>
              <div className="each-slide sp">
                <div
                  style={{
                    backgroundImage: `url(${spSlideImages[0]})`,
                    height: '300px',
                  }}
                />
              </div>
            </div>
          </section>

          <section className="top-page-restaurant">
            <div className="top-page-selection">
              <div className="top-page-selection-title-box">
                <div className="top-page-selection-title">
                  <img
                    src="/assets/img/chefle/booking_logo_o.png"
                    alt="来店予約ロゴ"
                  />
                  <h1 Style="font-size: 19px; font-weight:bold; margin-left: 10px;color: #5c5c5c;font-family: 'Sawarabi Mincho', sans-serif; line-height: 1.2;">
                    人気高級レストラン
                    <br />
                    来店予約サービス
                  </h1>
                </div>
                <p>About our service</p>
                {/* 特集 */}
                {/* <div className="pc special-feature">
                  <a href="https://peraichi.com/landing_pages/view/cheflekyotoprice">
                    <p>京都 5000円ランチ特集</p>
                  </a>
                </div> */}
              </div>

              <div className="top-page-restaurant-title-box">
                <div className="top-page-selection-head-eat-link">
                  <WhereToEat
                    areas={this.props.areas.data}
                    label={areaName}
                    urlKey={areaKey}
                    onSelect={this.changeWhereToEat}
                  />
                  <WhatToEat
                    categories={this.props.categories.data}
                    label={categoryName}
                    urlKey={categoryKey}
                    onSelect={this.changeWhatToEat}
                  />
                </div>
                {/* 特集 */}
                {/* <div className="sp special-feature">
                  <a href="https://peraichi.com/landing_pages/view/cheflekyotoprice">
                    <p>京都 5000円ランチ特集</p>
                  </a>
                </div> */}
              </div>
              {/* <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  即時予約可能なレストランラインナップ
                </h2>
                {immediateRestaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!immediateRestaurantList.items.length &&
                        immediateRestaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!immediateRestaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(immediateRestaurantList)}
                </ul>
              </div> */}
              <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  来店予約レストランラインナップ
                </h2>
                <h1 className="top-page-stores-title" Style="margin-top: 20px;">
                  {headDescription}
                </h1>
                <p
                  className="top-page-stores-description"
                  Style="line-height: 1.4;"
                >
                  {description}
                </p>
                {/* <SortSelector onSelect={this.sortPopularity} /> */}
                {restaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!restaurantList.items.length &&
                        restaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!restaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(restaurantList)}
                </ul>
              </div>
              <div className="slide-sevices sp">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="来店予約"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      来店予約レストラン
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_chefle.png"
                      alt="在宅レストラン"
                    />
                    <span Style="background-color: white;">
                      在宅レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取"
                    />
                    <span Style="background-color: white;">
                      店頭受取レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="キッチンバナー"
                    />
                    <span Style="background-color: white;">
                      お取り寄せ一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </section>

          <SideMenu />
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChefleMenuContainer)
