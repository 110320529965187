import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FAQ from '../common/FAQ'
import { SideMenu, MenuIcon } from '../menu/SideMenu'
import PcMenu from '../menu/PcMenu'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'

const modalSettingsPC = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '1000',
  },
  content: {
    margin: 'auto',
    maxWidth: '800px',
    maxHeight: '600px',
    borderRadius: '18px',
    zIndex: '1000',
  },
}

const modalSettingsSP = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '1000',
  },
  content: {
    margin: 'auto -20px',
    maxWidth: '800px',
    maxHeight: '400px',
    borderRadius: '18px',
    zIndex: '1000',
  },
}

const modalSettingsSP2 = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '1000',
  },
  content: {
    margin: 'auto -20px',
    maxWidth: '800px',
    maxHeight: '400px',
    borderRadius: '18px',
    zIndex: '1000',
  },
}

const isKitchen = () => {
  const url = window.location.pathname
  return url === '/kitchen'
}

const PcHeader = props => {
  let authUser = props.authUser
  let isLogin = false
  if (authUser && authUser.authInfo.isLogin) {
    isLogin = true
  }

  const headerMenu = [
    {
      name: '来店予約',
      type: 'chefle-menu',
      url: '/',
    },
    {
      name: '在宅レストラン',
      type: 'chefle',
      url: '/catering',
    },
    {
      name: '店頭受取',
      type: 'pickup',
      url: '/pickup',
    },
    {
      name: 'シェフルキッチン',
      type: 'chefle-kitchen',
      url: '/kitchen',
    },
    {
      name: 'コミュニティ',
      type: 'community',
      url: '/community',
    },
  ]

  const [modalChatIsOpen, setModalChatIsOpen] = useState(false)

  return (
    <header className="header pc">
      <div
        className="top-page-logo"
        Style="display: flex; align-items: center;"
      >
        <Link to="/">
          <img src="/assets/img/chefle/chefle_logo_b.png" alt="Chefle" />
        </Link>
        <div className="top-page-header-menu">
          <ul className="top-page-header-menu-ul">
            {headerMenu.map(list => (
              <li
                className={props.reservationType === list.type ? 'active' : ''}
              >
                <Link to={list.url}>{list.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="top-page-header-menu">
        <ul className="top-page-header-menu-ul">
          {!isLogin && (
            <li>
              <Link to="/sign_in">ログイン</Link>
            </li>
          )}
          {!isLogin && (
            <li>
              <Link to="/sign_up">新規登録</Link>
            </li>
          )}

          {isLogin && (
            <li>
              Go To Eat/Chefleポイント :{' '}
              {authUser.userInfo.effectivePoint
                ? authUser.userInfo.effectivePoint
                : 0}
              P
            </li>
          )}
          {isLogin && (
            <PcMenu
              logout={props.logout}
              userName={
                (authUser.userInfo.sei ? authUser.userInfo.sei : '') +
                ' ' +
                (authUser.userInfo.mei ? authUser.userInfo.mei : '')
              }
            />
          )}
          <li Style="cursor: pointer;">
            <FAQ text={'FAQ'} btnclassname={'btn-container-item'} />
          </li>
        </ul>
        {/* チャット */}
        <div className="chat-content">
          <a
            className="chat-button"
            href={() => false}
            onClick={() => setModalChatIsOpen(true)}
          >
            <img
              className="chat-icon"
              src="/assets/img/chefle/chaticon2.png"
              alt="chat-icon"
            />
            <span className="chat-text">
              コンシェルジュ
              <br />
              サポート
            </span>
          </a>
        </div>
        {isKitchen() && (
          <div className="chat-content">
            <a
              className="chat-button-2"
              href="https://shop.chefle.com/products/gift-card"
            >
              <FontAwesomeIcon icon={faGift} className="chat-ribbon" />
              <span className="chat-text-2">ギフト券</span>
            </a>
          </div>
        )}
      </div>
      <Modal
        closeTimeoutMS={200}
        isOpen={modalChatIsOpen}
        onRequestClose={() => setModalChatIsOpen(false)}
        style={modalSettingsPC}
        contentLabel="CancelationPolicy Modal"
      >
        <div class="chat-modal">
          <button
            className="chat-modal-close-button"
            onClick={() => setModalChatIsOpen(false)}
          >
            <img
              src="/assets/img/close_icon_01.svg"
              alt="close-icon"
              Style="width: 30px"
            />
          </button>
          <div className="chat-modal-top">
            <img
              className="chat-modal-icon"
              src="/assets/img/chefle/chefle_logo_o.png"
              alt="chat-modal-icon"
            />
            <p className="chat-modal-top-text">Concierge Support</p>
          </div>
          <p className="chat-modal-head-text">
            【Chefle シェフル】
            <br />
            LINEコンシェルジュサポートについて
          </p>
          <p className="chat-modal-content">
            Chefleでは、予約に関する質問や、予約状況の確認、最新情報などLINEより行うことが可能です。
            <br />
            また、予約困難店の空席情報も共有させていただきます。
            <br />
            ご利用するためにLINEでお友達登録して頂く必要がございます。
          </p>
          <div className="chat-modal-qr">
            <img
              className="chat-modal-qr-image"
              src="/assets/img/chefle/line-QR.jpg"
              alt="chat-modal-qr"
            />
            <p className="chat-modal-qr-text">
              LINEアプリから
              <br />
              お友達申請
            </p>
          </div>
        </div>
      </Modal>
    </header>
  )
}

const SpHeader = props => {
  let authUser = props.authUser
  const [modalChatIsOpen, setModalChatIsOpen] = useState(false)
  const [modalBeginnerIsOpen, setModalBeginnerIsOpen] = useState(false)

  return (
    <header className="header sp">
      {authUser.authInfo.isLogin ? (
        <Link
          to="/"
          Style="width: 100%;background-color: #f4f4f4;height: 50px;position: fixed;z-index: 2;"
        >
          <img
            src="/assets/img/chefle/chefle_logo_o.png"
            Style="width: calc(100% - 257px); background-color: #f4f4f4; width: calc(102%-241px); height: 50px; object-fit: contain; position: fixed; z-index: 2;"
            alt="Chefle"
          />
        </Link>
      ) : (
        <Link
          to="/"
          Style="width: 100%;background-color: #f4f4f4;height: 50px;position: fixed;z-index: 2;"
        >
          <img
            src="/assets/img/chefle/chefle_logo_o.png"
            Style="height: 50px;object-fit: contain;position: fixed;z-index: 2;left: -128px;top: 0;"
            alt="Chefle"
          />
        </Link>
      )}
      <div className="top-page-header">
        <div className="sp-menu">
          <a
            className="chat-icon-button"
            href={() => false}
            onClick={() => setModalChatIsOpen(true)}
          >
            <img
              className="chat-icon-baloon"
              src="/assets/img/chefle/chaticon.png"
              alt="チャットアイコン"
            />
            <span className="chat-icon-text">サポート</span>
          </a>
          <Link
            className="chat-icon-button2"
            Style="padding: 10px 20px;"
            to="/community"
          >
            <FontAwesomeIcon icon={faUserGroup} className="chat-community" />
            <span className="chat-icon-text">コミュニティ</span>
          </Link>
          {isKitchen() && (
            <React.Fragment>
              <a
                className="chat-icon-button3"
                href="https://shop.chefle.com/products/gift-card"
              >
                <FontAwesomeIcon icon={faGift} className="chat-ribbon" />
                <span className="chat-icon-text">ギフト券</span>
              </a>
            </React.Fragment>
          )}
          <Modal
            closeTimeoutMS={200}
            isOpen={modalChatIsOpen}
            onRequestClose={() => setModalChatIsOpen(false)}
            style={modalSettingsSP}
            contentLabel="CancelationPolicy Modal"
          >
            <div class="chat-modal">
              <button
                className="chat-modal-close-button"
                onClick={() => setModalChatIsOpen(false)}
              >
                <img
                  src="/assets/img/close_icon_01.svg"
                  alt="close-icon"
                  Style="width: 20px"
                />
              </button>
              <div className="chat-modal-top">
                <img
                  className="chat-modal-icon"
                  src="/assets/img/chefle/chefle_logo_o.png"
                  alt="chat-modal-icon"
                />
                <p className="chat-modal-top-text">Concierge Support</p>
              </div>
              <p className="chat-modal-head-text">
                【Chefle シェフル】
                <br />
                LINEコンシェルジュ
                <br />
                サポートについて
              </p>
              <p className="chat-modal-content">
                Chefleでは、予約に関する質問や、予約状況の確認、最新情報などLINEより行うことが可能です。
                <br />
                また、予約困難店の空席情報も共有させていただきます。
                <br />
                ご利用するためにLINEでお友達登録して頂く必要がございます。
              </p>
              <div className="chat-modal-qr">
                <a href="https://lin.ee/kuYTXl9" Style="position: relative">
                  <img
                    className="chat-modal-qr-image"
                    src="/assets/img/chefle/line.jpg"
                    alt="chat-modal-qr"
                  />
                  <span className="chat-modal-qr-text">
                    コンシェルジュサポート
                  </span>
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            closeTimeoutMS={200}
            isOpen={modalBeginnerIsOpen}
            onRequestClose={() => setModalBeginnerIsOpen(false)}
            style={modalSettingsSP2}
            contentLabel="CancelationPolicy Modal"
          >
            <div class="chat-modal">
              <button
                className="chat-modal-close-button2"
                onClick={() => setModalBeginnerIsOpen(false)}
              >
                <img
                  src="/assets/img/close_icon_01.svg"
                  alt="close-icon"
                  Style="width: 20px"
                />
              </button>
              <div className="chefle-kitchen__beginner">
                <img
                  className="chefle-kitchen__beginner-image"
                  src="/assets/img/chefle/chefle-kitchen-logo.png"
                  alt="キッチンロゴ"
                />
                <p className="chefle-kitchen__beginner-description-new">
                  新サービス
                </p>
                <p className="chefle-kitchen__beginner-description">
                  シェフルキッチンとは
                </p>
                <p className="chefle-kitchen__beginner-text">
                  新型コロナウイルスの影響により、現在多くの飲食店様にとって厳しい環境ではありますが、工夫しながらこれまでに培った技による食の提供を目指していらっしゃいます。我々も今できることとして、このお取り寄せ＋レシピ動画企画にて、人気の高い有名高級店の技術そして食材を、皆様のご自宅でご堪能いただけたら幸いです。
                </p>
                {!isKitchen() && (
                  <Link to="/kitchen" className="chefle-kitchen__beginner-link">
                    シェフルキッチンページへ
                  </Link>
                )}
              </div>
            </div>
          </Modal>
          {authUser.authInfo.isLogin && (
            <ul className="user-info sp">
              <li>GoToEat/Chefleポイント</li>
              <li>{authUser.userInfo.effectivePoint}P</li>
              <li>
                {authUser.userInfo.sei} {authUser.userInfo.mei}
              </li>
            </ul>
          )}
          <MenuIcon className="menu-icon-button" isIconWite={true} />
          <SideMenu
            className="close-icon-button"
            authUser={authUser}
            logout={props.logout}
          />
        </div>
      </div>
    </header>
  )
}
export { PcHeader, SpHeader }
