import React from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { fetchRestaurantList } from '../../actions/restaurantList'
import { fetchImmediateRestaurantList } from '../../actions/immediateRestaurantList'
import isEqual from 'lodash/isEqual'
import { AboutChefle, AboutChefleIcon } from '../../components/menu/AboutChefle'
import { RestaurantCard } from '../../components/restaurant/RestaurantCard'
import { getAreaName } from '../../components/top/WhereToEat'
import { getCategoryName } from '../../components/top/WhatToEat'
import { fetchAreas } from '../../actions/areas'
import { fetchCategories } from '../../actions/categories'
import { getActiveCampaign } from '../../actions/campaign'
import { history } from '../../configureStore'
import cleanDeep from 'clean-deep'
import { Helmet } from 'react-helmet'
import BackgroundVideo from '../../components/common/Video'
import { PcHeader, SpHeader } from '../../components/top/Header'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import ReactLoading from 'react-loading'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    restaurantList: state.restaurantList,
    immediateRestaurantList: state.immediateRestaurantList,
    areas: state.areas,
    categories: state.categories,
    campaign: state.campaign,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetch: bindActionCreators(fetchRestaurantList, dispatch),
    fetchImmediateRestaurantList: bindActionCreators(
      fetchImmediateRestaurantList,
      dispatch
    ),
    fetchAreas: bindActionCreators(fetchAreas, dispatch),
    fetchCategories: bindActionCreators(fetchCategories, dispatch),
    getActiveCampaign: bindActionCreators(getActiveCampaign, dispatch),
  })
}

class TopContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
    }
  }

  componentDidMount() {
    super.componentDidMount()

    const urlQuery = queryString.parse(this.props.location.search)
    this.props.fetchAreas()
    this.props.fetchCategories()
    this.props.fetch(Object.assign(urlQuery, this.props.match.params))
    this.props.fetchImmediateRestaurantList(
      Object.assign(urlQuery, this.props.match.params)
    )
    this.props.getActiveCampaign()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (
      !prevProps.campaign.data.id &&
      this.props.campaign.data.id &&
      !Object.keys(queryString.parse(this.props.location.search)).length
    ) {
      history.push(history.location.pathname + '?campaign=active')
      return
    }

    const urlQuery = Object.assign(
      queryString.parse(this.props.location.search),
      this.props.match.params
    )
    const prevQuery = Object.assign(
      queryString.parse(prevProps.location.search),
      prevProps.match.params
    )
    if (!isEqual(urlQuery, prevQuery)) {
      this.props.fetch(urlQuery)
      this.props.fetchImmediateRestaurantList(urlQuery)
    }
  }

  addParamQueryString(param) {
    let query = queryString.parse(this.props.location.search)
    return queryString.stringify(Object.assign(query, param))
  }

  renderPaging = restaurantList => {
    const { currentPage, lastPage } = restaurantList

    let array = []
    let hasLast = false
    let hasFirst = false

    let first = Math.max(currentPage - 1, 1)
    let last = Math.min(first + 3, lastPage)
    for (let i = first; i <= last; i++) {
      array.push({
        no: i,
        current: Number(currentPage) === i ? true : false,
      })
      if (i === 1) {
        hasFirst = true
      }
      if (i === lastPage) {
        hasLast = true
      }
    }
    if (!hasFirst) {
      array.unshift({ no: '...', current: false })
      array.unshift({ no: 1, current: false })
    }
    if (currentPage !== lastPage && hasLast === false) {
      array.push({ no: '...', current: false })
      array.push({ no: lastPage, current: false })
    }
    return array.map(a => (
      <React.Fragment key={a.no + '-page'}>
        {!a.current ? (
          <li>
            <Link
              to={`${history.location.pathname}?${this.addParamQueryString({
                page: a.no,
              })}`}
            >
              {a.no}
            </Link>
          </li>
        ) : (
          <li className="top-page-store-pager-active">
            <a className="top-page-store-pager-active" href={() => false}>
              {a.no}
            </a>
          </li>
        )}
      </React.Fragment>
    ))
  }

  makeDescription = (areaName, categoryName, campaignSelect) => {
    let descriptionWords = []

    descriptionWords.push(
      'シェフル（Chefle）では、人気高級店所属のシェフがあなたのご自宅や指定場所に伺い、コース料理を提供する派遣サービス（ケータリング）をご提供しております。 なかなか予約の取れない高級店シェフを家族占め。プライベート空間で、贅沢な時間をお過ごしください。'
    )

    let result = descriptionWords.join(' ')
    return result
  }

  changeWhereToEat = area => {
    let url = this.createUrlWithoutPage(area, this.props.match.params.category)
    history.push(url)
  }

  changeWhatToEat = category => {
    let url = this.createUrlWithoutPage(this.props.match.params.area, category)
    history.push(url)
  }

  createUrlWithoutPage = (area, category) => {
    let url =
      area && category
        ? `/${area}/${category}`
        : area && !category
        ? `/${area}`
        : !area && category
        ? `/cuisine/${category}`
        : '/'

    let query = queryString.parse(history.location.search)
    delete query.page

    return url + '?' + queryString.stringify(query)
  }

  changeFilters = payload => {
    let query = queryString.parse(history.location.search)
    query = Object.assign(query, payload)
    let filterd = cleanDeep(query)
    delete filterd.page
    history.push(
      history.location.pathname + '?' + queryString.stringify(filterd)
    )
  }

  render() {
    const { restaurantList, authUser, immediateRestaurantList } = this.props
    const areaKey = this.props.match.params.area
    const categoryKey = this.props.match.params.category

    const areaName = getAreaName(this.props.areas.data, areaKey)
    const categoryName = getCategoryName(
      this.props.categories.data,
      categoryKey
    )

    const campaignSelect = queryString.parse(this.props.location.search)
      .campaign

    const madeDescription = this.makeDescription(
      areaName,
      categoryName,
      campaignSelect
    )
    const resultString = {
      padding: '20px 10px 10px 10px',
      color: '#707070',
      fontSize: '20px',
    }

    const head = {
      title: `シェフル(chefle) - 有名高級店をおうちで召し上がれ。${
        restaurantList.currentPage > 1
          ? `（${restaurantList.currentPage}ページ目）`
          : ''
      }`,
      description: madeDescription
        ? madeDescription
        : 'Reservation Service for travelers who visit Japan',
      page_url: 'https://chefle.com/catering',
      image: 'http://chefle.com/assets/img/ogp.jpg',
    }

    const spinnerProperties = isMobile
      ? { height: '30%', width: '30%' }
      : { height: '12%', width: '12%' }

    return (
      <>
        <div
          className={
            campaignSelect && this.props.campaign.data.id
              ? 'top-page campaign'
              : 'top-page'
          }
        >
          <Helmet>
            <link rel="canonical" href="https://chefle.com/catering" />
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta name="description" content={head.description} />
            <meta property="og:image" content={head.image} />
            <meta property="og:url" content={head.page_url} />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle'}
          />

          <section>
            <div className="top-page-top">
              <div className="slide-title">
                有名店シェフを家族占め
                <br />
                してみませんか？
                <p>在宅レストラン（出張シェフ）サービス「シェフル」</p>
              </div>
              <div className="slide-sevices pc">
                <Link to="/" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    来店予約サービス
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    予約の取りにくいお店もChefleが代行予約
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      Style="height: 114px; object-fit:cover;"
                      src="/assets/img/chefle/top-small.jpg"
                      alt="来店予約レストラン"
                    />
                    <span Style="background-color: white;">
                      来店予約レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/catering" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_chefle.png"
                      alt="在宅レストラン"
                    />
                    <span Style="color: white; background-color: #ff5800;">
                      在宅レストラン
                      <img
                        src="/assets/img/chefle/down_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/pickup" Style="position: relative">
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/to_pickup.png"
                      alt="店頭受取レストラン"
                    />
                    <span Style="background-color: white;">
                      店頭受取レストラン一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
                <Link to="/kitchen" Style="position: relative">
                  <span className="chefle-kitchen__banner-header">
                    シェフルキッチン
                  </span>
                  <span className="chefle-kitchen__banner-text">
                    プロが目利きした食材をご自宅にお届け
                  </span>
                  <div className="slide-sevices-item">
                    <img
                      src="/assets/img/chefle/chefle-kitchen-banner.png"
                      alt="シェフるキッチンバナー"
                    />
                    <span Style="background-color: white;">
                      お取り寄せ一覧へ
                      <img
                        src="/assets/img/chefle/right_arrow_to_service.png"
                        alt=""
                      />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="each-slide slide-video">
                <BackgroundVideo />
              </div>
            </div>
          </section>
          <section className="pc">
            <div className="video">
              <iframe
                title="video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ZhEWi_WJ-_U"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>

            <div className="top-page-selection">
              <div className="top-page-restaurant-title-box">
                <p>シェフルへの想い</p>
                <p>Thoughts about the Chefle</p>
              </div>
              <div className="thoughts-about-the-chefle">
                私たちは、これまで訪日外国人向けにレストラン予約サービス
                <b>”JPNEAZY（ジャパニージー）”</b>
                を展開し、外国人に人気の高い有名高級店を中心に全国の飲食店とお取引をさせていただいております。
                現在、新型コロナウイルスの影響により、多くの飲食店様にとって厳しい状況が続いております。
                我々が今できることとして、コロナウイルスに対応した”安全基準”に則り、在宅サービス（出張シェフ）により有名高級店を皆様のご自宅でご堪能いただけたらと考え、
                <b>”シェフル”</b>をスタートしました。
                食を通して、みなさんの笑顔が戻ってくることを信じて、選びぬかれた飲食店との出会いをお手伝いできればと願っております。
                <br />
                <br />
                <p Style="display: flex; position: relative; font-family: 'Sawarabi Mincho', sans-serif;">
                  <span>株式会社Fesbase 代表取締役 金田 雅人</span>
                </p>
              </div>
            </div>
          </section>
          <section Style="background-color: #f4f4f4;">
            <div className="video sp">
              <iframe
                title="video"
                width="320"
                height="240"
                src="https://www.youtube.com/embed/ZhEWi_WJ-_U"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div className="top-page-selection">
              <div className="top-page-selection-title-box">
                <div className="top-page-selection-title">
                  <img
                    src="/assets/img/chefle/chefle_logo_o.png"
                    alt="在宅レストランサービス"
                  />
                  <h1 Style="font-size: 19px; font-weight:bold; margin-left: 10px;color: #5c5c5c;font-family: 'Sawarabi Mincho', sans-serif; line-height: 1.2;">
                    人気高級店シェフによる在宅サービス
                  </h1>
                </div>
                <p>About our service</p>
              </div>
              <div
                className="top-page-selection-head pc"
                Style="padding-top: 20px;"
              >
                <div className="top-page-selection-box">
                  <div className="top-page-selection-box-img">
                    <img
                      src="/assets/img/chefle/about_chefle1_pc.png"
                      alt="シェフルについて"
                      className="pc"
                      Style="width: 200px;"
                    />
                    <img
                      src="/assets/img/chefle/about_chefle1_sp.png"
                      alt="シェフルについて"
                      className="sp"
                    />
                  </div>
                  <p Style="padding: 20px 0 0;text-align: center;font-family: 'Sawarabi Mincho', sans-serif; font-weight:bold;">
                    厳選した人気飲食店を掲載
                  </p>
                  <p>
                    お食事のカテゴリー / 日程 / 時間 /
                    場所の決定権は全てあなたにあります。
                    <br />
                    コンシェルジュのサポートもご活用ください。
                  </p>
                </div>
                <div className="top-page-selection-box">
                  <div className="top-page-selection-box-img">
                    <img
                      src="/assets/img/chefle/about_chefle2_pc.png"
                      alt="シェフルについて"
                      className="pc"
                    />
                    <img
                      src="/assets/img/chefle/about_chefle2_sp.png"
                      alt="シェフルについて"
                      className="sp"
                    />
                  </div>
                  <p Style="padding: 20px 0 0;text-align: center;font-family: 'Sawarabi Mincho', sans-serif; font-weight:bold;">
                    WEBで簡単予約
                  </p>
                  <p>個別質問フォームの回答まで完了したら、あとは待つのみ。</p>
                </div>
                <div className="top-page-selection-box">
                  <div className="top-page-selection-box-img">
                    <img
                      src="/assets/img/chefle/about_chefle3_pc.png"
                      alt="シェフルについて"
                      className="pc"
                      Style="width: 200px;"
                    />
                    <img
                      src="/assets/img/chefle/about_chefle3_sp.png"
                      alt="シェフルについて"
                      className="sp"
                    />
                  </div>
                  <p Style="padding: 20px 0 0;text-align: center;font-family: 'Sawarabi Mincho', sans-serif; font-weight:bold;">
                    シェフがご自宅で一流の料理を調理
                  </p>
                  <p>
                    贅沢を味わいましょう。
                    <br />
                    当日は、日常の疲れを癒し、食があなたを幸せの絶頂へともたらします。
                  </p>
                </div>
              </div>

              <div className="slide-sevices sp">
                <div className="about-chefle">
                  <AboutChefleIcon isIconWite={true} />
                  <AboutChefle />
                </div>
              </div>
            </div>
          </section>
          <section className="top-page-restaurant">
            <div className="top-page-selection">
              {/* <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  即時予約可能なレストランラインナップ
                </h2>
                {immediateRestaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!immediateRestaurantList.items.length &&
                        immediateRestaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!immediateRestaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(immediateRestaurantList)}
                </ul>
              </div> */}
              <div className="top-page-store-area">
                <h2 Style="font-weight: normal; text-align: center; color: #5c5c5c;">
                  在宅レストランラインナップ
                </h2>
                {restaurantList.loadingFlag ? (
                  <React.Fragment>
                    <div className="loading-spinner-text">Now Loading</div>
                    <div className="loading-spinner">
                      <ReactLoading
                        type="spin"
                        color="#ff5800"
                        {...spinnerProperties}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <ul className="store-card-container">
                      {!!restaurantList.items.length &&
                        restaurantList.items.map(r => (
                          <>
                            <RestaurantCard key={r.id} restaurant={r} />
                          </>
                        ))}
                    </ul>

                    {!restaurantList.items.length && (
                      <div style={resultString}>No results</div>
                    )}
                  </>
                )}
                <ul className="top-page-store-pager">
                  {this.renderPaging(restaurantList)}
                </ul>
              </div>
            </div>
          </section>
          <section className="sp">
            <div className="top-page-selection">
              <div className="top-page-restaurant-title-box">
                <p>シェフルへの想い</p>
                <p>Thoughts about the Chefle</p>
              </div>
              <div Style="line-height: 25px; padding: 20px 14px 40px;">
                私たちは、これまで訪日外国人向けにレストラン予約サービス
                <b>”JPNEAZY（ジャパニージー）”</b>
                を展開し、外国人に人気の高い有名高級店を中心に全国の飲食店とお取引をさせていただいております。
                現在、新型コロナウイルスの影響により、多くの飲食店様にとって厳しい状況が続いております。
                我々が今できることとして、コロナウイルスに対応した”安全基準”に則り、在宅サービス（出張シェフ）により有名高級店を皆様のご自宅でご堪能いただけたらと考え、
                <b>”シェフル”</b>をスタートしました。
                食を通して、みなさんの笑顔が戻ってくることを信じて、選びぬかれた飲食店との出会いをお手伝いできればと願っております。
                <br />
                <br />
                <p Style="display: flex; position: relative;">
                  <span>株式会社Fesbase 代表取締役 金田 雅人</span>
                </p>
              </div>
            </div>
            <div className="slide-sevices sp">
              <Link to="/" Style="position: relative">
                <span className="chefle-kitchen__banner-header">
                  来店予約サービス
                </span>
                <span className="chefle-kitchen__banner-text">
                  予約の取りにくいお店もChefleが代行予約
                </span>
                <div className="slide-sevices-item">
                  <img
                    Style="height: 114px; object-fit:cover;"
                    src="/assets/img/chefle/top-small.jpg"
                    alt="来店予約レストラン"
                  />
                  <span Style="background-color: white;">
                    来店予約レストラン一覧へ
                    <img
                      src="/assets/img/chefle/right_arrow_to_service.png"
                      alt=""
                    />
                  </span>
                </div>
              </Link>
              <Link to="/catering" Style="position: relative">
                <div className="slide-sevices-item">
                  <img
                    src="/assets/img/chefle/to_chefle.png"
                    alt="在宅レストラン"
                  />
                  <span Style="color: white; background-color: #ff5800;">
                    在宅レストラン
                    <img
                      src="/assets/img/chefle/down_arrow_to_service.png"
                      alt=""
                    />
                  </span>
                </div>
              </Link>
              <Link to="/pickup" Style="position: relative">
                <div className="slide-sevices-item">
                  <img src="/assets/img/chefle/to_pickup.png" alt="店頭受取" />
                  <span Style="background-color: white;">
                    店頭受取レストラン一覧へ
                    <img
                      src="/assets/img/chefle/right_arrow_to_service.png"
                      alt=""
                    />
                  </span>
                </div>
              </Link>
              <Link to="/kitchen" Style="position: relative">
                <span className="chefle-kitchen__banner-header">
                  シェフルキッチン
                </span>
                <span className="chefle-kitchen__banner-text">
                  プロが目利きした食材をご自宅にお届け
                </span>
                <div className="slide-sevices-item">
                  <img
                    src="/assets/img/chefle/chefle-kitchen-banner.png"
                    alt="シェフルキッチンバナー"
                  />
                  <span Style="background-color: white;">
                    お取り寄せ一覧へ
                    <img
                      src="/assets/img/chefle/right_arrow_to_service.png"
                      alt=""
                    />
                  </span>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopContainer)
