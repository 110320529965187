import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import Restaurant from '../containers/chefle/RestaurantContainer'
import PickupRestaurant from '../containers/pickup/PickupRestaurantContainer'
import ChefleMenuRestaurant from '../containers/chefle-menu/ChefleMenuRestaurantContainer'
import Top from '../containers/chefle/TopContainer'
import Pickup from '../containers/pickup/PickupContainer'
import ChefleMenu from '../containers/chefle-menu/ChefleMenuContainer'
import ChefleKitchen from '../containers/chefle-kitchen/ChefleKitchenContainer'
import Community from '../containers/community/Community'
import SignUp from '../containers/sign-up/SignUpContainer'
import SignUpConfirm from '../containers/sign-up/SignUpConfirmContainer'
import ResendEmailComplete from '../containers/sign-up/ResendEmailCompleteContainer'
import SignUpComplete from '../containers/sign-up/SignUpCompleteContainer'
import ResendEmail from '../containers/sign-up/ResendEmailContainer'
import SignIn from '../containers/sign-in/SignInContainer'
import MenuReserveForm from '../containers/chefle/MenuReserveFormContainer'
import MenuReserveConfirm from '../containers/chefle/MenuReserveConfirmContainer'
import PickupReserveForm from '../containers/pickup/PickupReserveFormContainer'
import PickupReserveConfirm from '../containers/pickup/PickupReserveConfirmContainer'
import ChefleMenuReserveForm from '../containers/chefle-menu/ChefleMenuReserveFormContainer'
import ChefleMenuReserveConfirm from '../containers/chefle-menu/ChefleMenuReserveConfirmContainer'
import ReserveComplete from '../components/ReserveComplete'
import Payment from '../containers/PaymentContainer'
import PaymentComplete from '../components/PaymentComplete'
import AboutGoToEat from '../containers/AboutGoToEat'

import AccountUpdate from '../containers/setting/userinfo/AccountUpdate'
import AccountUpdateComplete from '../containers/setting/userinfo/AccountUpdatetComplete'
import PasswordUpdate from '../containers/setting/pass/PasswordUpdate'
import PasswordUpdateComplete from '../containers/setting/pass/PasswordUpdateComplete'
import Reservation from '../containers/setting/reservation/Reservation'
import CreditCard from '../containers/setting/creditcard/CreditCard'
import CreditCardPayment from '../containers/setting/creditcard/CreditCardPayment'
import AccountDelete from '../containers/setting/delete/AccountDelete'
import AccountDeleteConfirm from '../containers/setting/delete/AccountDeleteConfirm'
import AccountDeleteComplete from '../containers/setting/delete/AccountDeleteComplete'
import PrivacyPolicy from '../containers/PrivacyPolicy'

const routes = (
  <div>
    <Switch>
      <Route exact path="/catering" component={Top} />
      <Route exact path="/gotoeat" component={AboutGoToEat} />
      <Route exact path="/pickup" component={Pickup} />
      <Route exact path="/kitchen" component={ChefleKitchen} />
      <Route path="/community" component={Community} />
      <Route exact path="/" component={ChefleMenu} />
      <Route exact path="/sign_up" component={SignUp} />
      <Route exact path="/sign_up/confirm" component={SignUpConfirm} />
      <Route path="/sign_up/complete" component={SignUpComplete} />
      <Route
        path="/sign_up/resend_email/complete"
        component={ResendEmailComplete}
      />
      <Route path="/sign_up/resend_email" component={ResendEmail} />
      <Route exact path="/sign_in" component={SignIn} />

      <Route
        path="/setting/user_info/updated"
        component={AccountUpdateComplete}
      />
      <Route path="/setting/user_info" component={AccountUpdate} />
      <Route
        path="/setting/password/updated"
        component={PasswordUpdateComplete}
      />
      <Route path="/setting/reservation" component={Reservation} />
      <Route path="/setting/creditcard" component={CreditCard} />
      <Route path="/setting/password" component={PasswordUpdate} />
      <Route path="/setting/delete/confirm" component={AccountDeleteConfirm} />
      <Route
        path="/setting/delete/complete"
        component={AccountDeleteComplete}
      />
      <Route path="/setting/delete" component={AccountDelete} />

      <Route
        path="/restaurants/reservation/complete"
        component={PaymentComplete}
      />
      <Route
        path="/restaurants/:restaurantId/menu-reservation/form"
        component={MenuReserveForm}
      />
      <Route
        path="/restaurants/menu-reservation/confirm"
        component={MenuReserveConfirm}
      />
      <Route
        path="/pickup/restaurants/:restaurantId/menu-reservation/form"
        component={PickupReserveForm}
      />
      <Route
        path="/pickup/restaurants/menu-reservation/confirm"
        component={PickupReserveConfirm}
      />
      <Route
        path="/menu/restaurants/:restaurantId/menu-reservation/form"
        component={ChefleMenuReserveForm}
      />
      <Route
        path="/menu/restaurants/menu-reservation/confirm"
        component={ChefleMenuReserveConfirm}
      />
      <Route
        path="/restaurants/menu-reservation/credit/:reservationId"
        component={CreditCardPayment}
      />
      <Route
        path="/restaurants/menu-reservation/complete"
        component={PaymentComplete}
      />
      <Route path="/restaurants/:restaurantId" component={Restaurant} />
      <Route
        path="/pickup/restaurants/:restaurantId"
        component={PickupRestaurant}
      />
      <Route
        path="/menu/restaurants/:restaurantId"
        component={ChefleMenuRestaurant}
      />
      <Route path="/payment/complete" component={PaymentComplete} />
      <Route path="/payment/:reservationId" component={CreditCardPayment} />
      <Route path="/menu/cuisine/:category" component={ChefleMenu} />
      <Route path="/menu/:area/:category" component={ChefleMenu} />
      <Route path="/menu/:area" component={ChefleMenu} />

      <Route path="/privacy_policy" component={PrivacyPolicy} />

      <Route exact path="*" render={() => <Redirect to="/" />} />
    </Switch>
  </div>
)

export default routes
